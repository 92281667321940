import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./App.css";

const cur_host = "http://laravel/"; //debug


const isBadField = (_field, errors) => 
{
    if (errors.bad_fields.length) {
        let msg = errors.bad_fields.find(field => field.field === _field);
        if (msg && msg.msg)
            return "(" + msg.msg + ")";
    }
    return null;
}

const renderErrorMessage = (_field, errors) => (
    <div className="sign-error-msg">{isBadField(_field, errors)}</div>
);

const refreshPage = () => window.location.reload();

function SignIn() 
{
const [errors, setError] = useState({ "msg":null, "bad_fields":[{}] });

const submitForm = (event) => {
    event.preventDefault();
    
    fetch(cur_host + "ru/user/signin", {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify ({
            "username": event.target.username.value,
            "password": event.target.password.value,
            "remember": event.target.remember.checked
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data['success'] == true) {
            if (!('message' in data)) {
                refreshPage();
            }
        }
        else {
            let bad_fields = [];
            Object.keys(data['bad_fields']).map(field => {
                let err_msg = data['bad_fields'][field][0];
                bad_fields.push({
                    "field": field,
                    "msg": err_msg
                });
            });
            setError({ 
                "msg": data['message'],
                "bad_fields": bad_fields
            });
        }
    });
}

return (
            <div className="sign-form sign-in">
                <form method="POST" onSubmit={e => submitForm(e)}>
                <h2 className="h2-title">Welcome</h2>
                <label className="sign-label">
                    <span className={isBadField("username", errors) ? "span-error" : "sign-span"}>Username</span>
                    <input type="text" name="username"  className="sign-input"  />
                </label>
                {renderErrorMessage("username", errors)}
                <label className="sign-label">
                    <span className={isBadField("password", errors) ? "span-error" : "sign-span"} >Password</span>
                    <input type="password" name="password"  className="sign-input" />
                </label>
                {renderErrorMessage("password", errors)}
                <p className="forgot-pass one_line">
                <label className="sign-label unselectable">
                <input type="checkbox" name="remember" defaultChecked={true} />&nbsp;Remember me?</label></p>
                <button type="submit" className="book-a-table-btn scrollto d-none d-lg-flex sign-button-last">Sign In</button>
                </form>
            </div>
);
}


function SignUp() 
{
const [errors, setError] = useState({ "msg":null, "bad_fields":[{}] });


const submitForm = (event) => {
    event.preventDefault();
    
    fetch(cur_host + "ru/user/signup", {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "username": event.target.username.value,
            "password": event.target.password.value,
            "email": event.target.email.value
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data['success'] == true) {
            if (!('message' in data)) {
                refreshPage();
            }
        }
        else {
            let bad_fields = [];
            Object.keys(data['bad_fields']).map(field => {
                let err_msg = data['bad_fields'][field][0];
                bad_fields.push({
                    "field": field,
                    "msg": err_msg
                });
            });
            setError({ 
                "msg": data['message'],
                "bad_fields": bad_fields
            });
        }
    });
}


const changeToLogin = () =>  {
    document.querySelector('.cont').classList.toggle('s--signup');
}

return (
            <div className="sub-cont">
                <div className="img">
                    <div className="img__text m--up">
                        <h4>Don't have an account? Please Sign up!</h4>
                    </div>
                    <div className="img__text m--in">
                        <h4>If you already has an account, just sign in.</h4>
                    </div>
                    <div className="img__btn" onClick={changeToLogin}>
                        <span className="m--up">Sign Up</span>
                        <span className="m--in">Sign In</span>
                    </div>
                </div>
                <div className="sign-form sign-up">
                    <form method="POST" onSubmit={e => submitForm(e)}>
                    <h2 className="h2-title">Create your Account</h2>
                    <label className="sign-label">
                        <span className={isBadField("username", errors) ? "span-error" : "sign-span"}>Username</span>
                        <input type="text" name="username" className="sign-input" />
                    </label>
                    {renderErrorMessage("username", errors)}
                    <label className="sign-label">
                        <span className={isBadField("email", errors) ? "span-error" : "sign-span"}>Email</span>
                        <input type="email" name="email" className="sign-input" />
                    </label>
                    {renderErrorMessage("email", errors)}
                    <label className="sign-label">
                        <span className={isBadField("password", errors) ? "span-error" : "sign-span"}>Password</span>
                        <input type="password" name="password"  className="sign-input" />
                    </label>
                    {renderErrorMessage("password", errors)}
                    <br /><button type="submit" className="book-a-table-btn scrollto d-none d-lg-flex sign-button-last">Sign Up</button>
                    </form>
                </div>
            </div>
    );
}

function App() 
{
const [isClosed, setClosed] = useState(false);

const closeWidget = () => {
    setClosed(true);
}

return (
    <div className="app">
        {isClosed===false && <> <br /><br /><br /><br /><br /><br />
                <div className="cont">
                <div className="xxx clicable" onClick={closeWidget} style={{position: "absolute", zIndex: 100, margin:10, right:0}} ></div>
                    <SignIn />
                    <SignUp />
                </div>
            </>
        }
    </div>
);
}

export default App;
